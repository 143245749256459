<template>
  <section class="py-5">
    <v-row>
      <v-col cols="12" justify="center" align-self="center" align="center">
        <div class="text-subtitle-1 main-grey">
          <v-btn text @click="$router.push({name: 'impressum'})">{{ $t('impressum') }}</v-btn> | <v-btn text @click="$router.push({name: 'datenschutz'})">{{ $t('datenschutz') }}</v-btn> |
            {{ $store.getters.settings.site_copyright || "" }}
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
    data(){
        return {
            impressumDialog: false,
            datenschutzDialog: false
        }
    },
};
</script>